<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mt-25">
          Purple MDM
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to Purple MDM 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account below
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="country">Country</label>
                </div>
                <b-form-select
                  id="country"
                  v-model="countrySelected"
                  :options="countryOptions"
                  @change="countryChange"
                />
              </b-form-group>

              <div class="d-flex align-center flex-wrap justify-content-between">
                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>
                <a class="text-primary ms-2 mb-1" @click="forgotPassword"> Forgot Password? </a>
              </div>

              <!-- Show login errors -->
              <b-alert v-model="showLoginErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ loginError }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>This is an invite only site</span>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import auth from '@/auth/auth'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CountryService from '../../services/CountryService';

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      loginError: '',
      countrySelected: CountryService.country,
      countryOptions: [
        { value: 'uk', text: 'United Kingdom' },
        { value: 'aus', text: 'Australia' },
      ],
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    showLoginErrors() {
      return this.loginError !== ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      return require('@/assets/images/logo/logo.svg')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // If in development mode show the Local Country option
    if (process.env.NODE_ENV === 'development') {
      this.countryOptions.push({ value: 'local', text: 'Localhost (Development)' })
    }

    CountryService.setCountryFromLocalStorage()
    this.countrySelected = CountryService.country
  },
  methods: {
    countryChange(country) {
      CountryService.updateCountry(country)
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
            auth.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(async response => {
              auth.setToken(response.data.accessToken)

              // On successful login, if authorised = false then 2FA is required.
              if (response.data.authorised === false) {
                // If they haven't set up their 2FA yet, let them do it now.
                if (response.data.enabled === false) {
                  await this.$router.push({ name: 'two-factor-setup' });
                  return
                }

                await this.$router.push({ name: 'two-factor-authorise' });
                return
              }

              // Response must be authorised so we should be able to use the refresh token to fetch the user details
              await auth.refreshCurrentUser()

              // Make sure they have some permissions
              if (auth.getCurrentUser().permissions == null) {
                this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
                return
              }

              this.$router.replace('/')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${auth.getCurrentUser().name}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged in. Now you can start to explore!',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginValidation.setErrors(error.response.data.error)
                })
            }, error => {
              if (error.response) {
                this.loginError = error.response.data.error
              } else {
                this.loginError = error.message
              }
            })
        }
      })
    },
    forgotPassword() {
      this.$router.push({ name: 'auth-forgot-password' })
    },
  },
}
</script>
